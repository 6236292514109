
import { stringifyQueryString } from '@shein/common-function'
import rankModalInstance from 'public/src/pages/rank_products_v2/rankDrawerModel.js'

const MODULE_TYPE = {
  detail: 'recommend', // 商详
  list: 'ranking_list', // 列表榜单组件
  listLabel: 'ranking_list_label', // 列表榜单标签
  infoflow: 'infoflow',
  listFeedBack: 'ranking_feedback',
  brandinfoflow: 'brandinfoflow', // 品牌频道信息流
  similarRank: '', // 落地页底部相似榜单的src_module取当前落地页的src_module
  storeItem: 'store_item', // 店铺 item tab
  storeHome: 'storeHomePage', // 店铺 home tab
}

/**
* 获取榜单落地页链接
* @param {*} param0 
*/
export const getRankProductsUrl = ({
  carrierSubType = '',
  langPath,
  params,
  from = 'list',
}) => {
  const queryObj = Object.assign({}, params, {
    carrierSubType: carrierSubType || params.carrierSubType,
    src_module: MODULE_TYPE[from] || params.src_module,
  })
  if (queryObj.contentCarrierId) {
    return `${langPath}/ranking_list/page?${stringifyQueryString({
      queryObj,
    })}`
  }
  return ''
}

/**
* 打开榜单
* @param type 榜单类型  url: 返回榜单链接  drawer: 打开榜单弹窗
* @param config 榜单方法配置
* @param params 榜单参数
* @returns url 或者 弹窗实例
*/
export const openRank = ({
  type = 'drawer',
  config = {},
  params = {},
} = {}) => {
  if (type === 'drawer') {
    rankModalInstance.showDrawer(params, config)
    return rankModalInstance
  }
  if (type === 'url') {
    let { langPath } = config
    langPath = langPath || gbCommonInfo?.langPath || ''

    const queryObj = { ...params, ...{ src_module: MODULE_TYPE[params.from] || params.src_module } }
    return `${langPath}/ranking_list/page?${stringifyQueryString({ queryObj })}`
  }
}
